import { all, put, takeEvery } from "redux-saga/effects";

export const statusActionTypes = {
  PUT_DISPLAY_SCREEN: "PUT_DISPLAY_SCREEN",
};

function* setDisplayScreenWorker(action) {
  try {
    yield put({
      type: "SET_DISPLAY_SCREEN",
      payload: {
        screen: action.payload.screen,
      },
    });
  } catch (error) {
    console.error(error);
  }
}

export default function* navigationWatcher() {
  yield all([takeEvery("PUT_DISPLAY_SCREEN", setDisplayScreenWorker)]);
}
