import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import profileReducer from "./profile/reducer";
import authReducer from "./authentication/reducer";
import petitionsReducer from "./petitions/reducer";
import navigationReducer from "./navigation/reducer";
import announcementReducer from "./announcements/reducer";
import volunteerReducer from "./volunteer/reducer";
import jobsReducer from "./jobs/reducer";
import appReducer from "./app/reducer";

const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: ["loading"],
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials"],
};

const announcementPersistConfig = {
  key: "announcement",
  storage: storage,
  blacklist: ["loading"],
};

const profilePersistConfig = {
  key: "profile",
  storage: storage,
  blacklist: ["loading"],
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"],
};

const petitionsPersistConfig = {
  key: "petitions",
  storage: storage,
  blacklist: ["loading"],
};

const volunteerPersistConfig = {
  key: "volunteer",
  storage: storage,
  blacklist: ["loading"],
};

const jobsPersistConfig = {
  key: "jobs",
  storage: storage,
  blacklist: ["loading"],
};

const navigationPersistConfig = {
  key: "navigation",
  storage: storage,
  blacklist: ["screen"],
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "auth",
    "profile",
    "status",
    "petitions",
    "jobs",
    "navigation",
    "volunteer",
    "announcement",
  ],
};

//root reducer
const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  announcements: persistReducer(announcementPersistConfig, announcementReducer),
  profile: persistReducer(profilePersistConfig, profileReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  petitions: persistReducer(petitionsPersistConfig, petitionsReducer),
  navigation: persistReducer(navigationPersistConfig, navigationReducer),
  volunteer: persistReducer(volunteerPersistConfig, volunteerReducer),
  jobs: persistReducer(jobsPersistConfig, jobsReducer),
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
